import { Button, Card, CardContent, Chip, Container, Divider, Grid, List, ListItem, ListItemButton, ListItemText, StepContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from '../vews/Header';
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { AccountBalanceWalletOutlined, AccountCircleOutlined, ArrowBackOutlined, ArrowForwardRounded, CloudDownloadOutlined, IcecreamOutlined, StorefrontOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faB, faPlay } from '@fortawesome/free-solid-svg-icons';
import { faAppStore, faChrome, faFirefox, faGooglePay } from '@fortawesome/free-brands-svg-icons';
import { Box } from '@mui/system';





const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <AccountBalanceWalletOutlined />,
    2: <AccountCircleOutlined />,
    3: <CloudDownloadOutlined />,
    4: <StorefrontOutlined />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['Create Wallet', 'Create Account', 'Download & Play', 'Buy Ninnekos'];


const useStyles = makeStyles((theme) => ({
  space: {
    height: 100
  },
  center: {
    justifyContent: "center",
    alignItems: "center"
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  download: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  card: {
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
      minWidth: 800,
    },
  }

}));

function CreateWallet({ classes }) {
  return (
    <>
      <Typography maxWidth="md">
        Wallet is used to store your token, NFTs
      </Typography>

      <Card variant="outlined" className={classes.card}>
        {/* <CreateWallet classes={classes} /> */}


        <CardContent>
          <List>
            <ListItem>
              <Typography>
                Download MetaMask extension for PC/MAC
              </Typography>
            </ListItem>
            <ListItem>
              <Grid container spacing={1} direction="row">
                <Grid item>
                  <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="blank" style={{ textDecoration: 'none' }}>
                    <Button variant='outlined' startIcon={<FontAwesomeIcon icon={faFirefox} />}>Chrome</Button>
                  </a>
                </Grid>
                <Grid item>
                  <a href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="blank" style={{ textDecoration: 'none' }}>
                    <Button variant='outlined' startIcon={<FontAwesomeIcon icon={faChrome} />}>Fire Fox</Button>
                  </a>
                </Grid>
              </Grid>
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem>
              <Typography>
                Download MetaMask Mobile App
              </Typography>
            </ListItem>
            <ListItem>
              <Grid container spacing={1} direction="row">
                <Grid item>
                  <a href="https://metamask.app.link/skAH3BaF99" target="blank" style={{ textDecoration: 'none' }}>
                    <Button variant='outlined' startIcon={<FontAwesomeIcon icon={faAppStore} />}>App Store</Button>
                  </a>
                </Grid>
                <Grid item>
                  <a href="https://metamask.app.link/bxwkE8oF99" target="blank" style={{ textDecoration: 'none' }}>
                    <Button variant='outlined' startIcon={<FontAwesomeIcon icon={faPlay} />}>Play Store</Button>
                  </a>
                </Grid>
              </Grid>
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem>
              <Typography>
                <p>
                  <a href='https://coinmarketcap.com/alexandria/article/connect-metamask-to-binance-smart-chain-bsc' target="_blank" rel="noreferrer" >
                    Connect MetaMask to Binance Smart Chain (BSC)
                  </a>
                </p>
                You can also use other Wallets: Trust Wallet, Safepal...
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  )
}

function CreateAccount({ classes }) {
  return (
    <>
      <Typography maxWidth="md">
        Account is used to login to Game client
      </Typography>

      <Card variant="outlined" className={classes.card}>

        <CardContent>


          <Stepper orientation="vertical">

            <Step active={true}>
              <StepLabel>Connect your Wallet</StepLabel>
              <StepContent>
                <Typography>
                  Go to &nbsp;
                  <a href="https://market.ninneko.com" target="blank" style={{ textDecoration: 'none' }}>
                    <Button color="primary">Market</Button>
                  </a>
                  <p>
                    Click "Connect Wallet" button
                  </p>
                  Follow instructions to connect your wallet
                </Typography>
              </StepContent>
            </Step>

            <Step active={true}>
              <StepLabel>Setup account</StepLabel>
              <StepContent>
                <Typography>
                  <p>
                    You will be asked for "Setup Account"
                  </p>
                  Setup your account with username and password
                </Typography>
              </StepContent>
            </Step>

          </Stepper>
        </CardContent>
      </Card>
    </>
  )
}

function BuyNinnekos({ classes }) {
  return (
    <>
      <Typography maxWidth="md">
        It's better to play with NFT Ninnekos
      </Typography>

      <Card variant="outlined" className={classes.card}>

        <CardContent>

          <List>
            <ListItem>
              <Typography>
                You can play with free Ninnekos
              </Typography>
            </ListItem>

            <Divider className={classes.divider} />

            <ListItem>
              <Typography>
                But if you want to earn

                <a href="https://whitepaper.ninneko.com/tokenomics/mata/" target="blank" style={{ textDecoration: 'none' }}>
                  <Button color="primary"
                    startIcon={<img src='/images/coin_mata.svg' alt='mata' width={40} />}
                  >MATA</Button>
                </a>

                token, you need to buy NFT Ninnekos on &nbsp;
                <a href="https://market.ninneko.com" target="blank" style={{ textDecoration: 'none' }}>
                  <Button color="primary" >Market</Button>
                </a>
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  )
}

function DownloadClient({ classes }) {
  return (
    <>
      <Typography maxWidth="md">
        Download Ninneko Game for PC/Mac/Android/iOS
      </Typography>

      <Card variant="outlined" className={classes.card}>

        <CardContent>

          <List>
            <ListItem>
              <Typography>
                Go to
                <a href="https://get.ninneko.com" target="blank" style={{ textDecoration: 'none' }}>
                  <Button color="primary" size="large" >get.ninneko.com</Button>
                </a>
                to download
              </Typography>
            </ListItem>


          </List>
        </CardContent>
      </Card>
    </>
  )
}




export default function StepLayout() {

  const [step, setStep] = React.useState(0)

  const nextStep = () => {
    if (step == 3) return
    setStep(step + 1)
  }

  const backStep = () => {
    if (step == 0) {
      window.location = '/'
      return
    }
    setStep(step - 1)
  }

  const selectStep = (index) => {
    setStep(index)
  }

  const classes = useStyles();
  return (
    <div>
      <Header />
      <div className={classes.space} />
      <Container maxWidth="lg" style={{ marginBottom: 100 }}>
        <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={label} onClick={() => selectStep(index)} >
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className={classes.space} />

        <Stack spacing={2} className={classes.center}>

          {step == 0 && <CreateWallet classes={classes} />}
          {step == 1 && <CreateAccount classes={classes} />}
          {step == 2 && <DownloadClient classes={classes} />}
          {step == 3 && <BuyNinnekos classes={classes} />}



          <div className={classes.center} style={{ marginTop: 32 }}>
            <Button startIcon={<ArrowBackOutlined />} style={{ marginRight: 16 }} onClick={backStep}>Back</Button>
            {step < 3 && <Button endIcon={<ArrowForwardRounded />} onClick={nextStep}>Next</Button>}
          </div>
        </Stack>
      </Container>
    </div>
  )

}