import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Header from '../vews/Header';





const useStyles = makeStyles((theme) => ({
    image: {
        height: 200
    },
    space: {
        height: 100
    },
    center: {
        justifyContent: "center",
        alignItems: "center"
    }

}));

export default function HomeLayout() {

    const classes = useStyles();
    return (
        <div>
            <Header />
            <div className={classes.space} />
            <Container maxWidth="lg" style={{marginBottom: 100}}>
                <Stack spacing={10}>
                    <Grid container spacing={8} className={classes.center}>
                        <Grid item>
                            <Stack spacing={2} className={classes.center}>
                                <img src="/images/wallet.png" className={classes.image} alt="connect" />
                                <Typography>1. Create Wallet</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack spacing={2} className={classes.center}>
                                <img src="/images/account2.png" className={classes.image} alt="connect" />
                                <Typography>2. Create Account</Typography>
                            </Stack>
                        </Grid>
                       
                        <Grid item>
                            <Stack spacing={2} className={classes.center}>
                                <img src="/images/play.png" className={classes.image} alt="connect" />
                                <Typography>3. Download &amp; Play</Typography>
                            </Stack>
                        </Grid>

                        <Grid item>
                            <Stack spacing={2} className={classes.center}>

                                <img src="/images/special.png" className={classes.image} alt="connect" />
                                <Typography>4. Buy Ninnekos</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Stack spacing={2} className={classes.center}>
                        <Typography variant='h3'>Getting Started With Ninneko</Typography>
                        <Typography maxWidth="md">
                            Ninneko is NFT game built on Binance Smart Chain that combines Idle RPG gameplay and Breeding system.
                        </Typography>
                        <Link to={"/step"} style={{textDecoration: 'none'}}>
                            <Button variant="contained" size="large">Get Started</Button>
                        </Link>
                    </Stack>
                </Stack >
            </Container>
        </div>


    )

}