import { useTheme } from "@emotion/react";
import { AppBar, Toolbar, useMediaQuery } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    appBar: {
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
        backgroundColor: 'white',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    logo: {
        textDecoration: 'none',
        marginRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    },
}));




export default function Header() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    return (
        <>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <a href="https://ninneko.com" target="blank" style={{ textDecoration: 'none' }}>
                        {
                            matches ?
                                <img src="/images/lg_169x47.png" alt="Ninneko" style={{ width: 151, height: 42 }} /> :
                                <img src="/images/lg_169x47.png" alt="Ninneko" style={{ width: 169, height: 47 }} />
                        }
                    </a>
                </Toolbar>
            </AppBar>
            <div className={classes.toolbar} />
        </>
    )


}